<template>
  <div class="grid-1">
    <div
      class="bg h-32 rounded-xl"
      :style="`background-image: url(${$img('help')})`"
    >
      <div
        class="w-full h-full bg-gray-800 text-white rounded-xl bg-opacity-60 center font-bold"
      >
        {{ $t("help.title") }}
      </div>
    </div>
    <a
      v-for="s in q"
      :key="s.id"
      :href="`https://web.facebook.com/laziri.fb/posts/${s.link}`"
      target="_blank"
      class="py-2 light2 rounded-xl font-semibold first-letter:uppercase text-center"
    >
      {{ $t(`help.${s.text}`) }}
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      q: [
        {
          text: "buy",
          link: "pfbid0LVCtKPv5WpWeLVoVFwVSWGpb9e9WCTX52HRQzPRzwonRhNbTQqktxxZCRQf74iGsl",
        },
        {
          text: "store",
          link: "pfbid02eP8ub6NsU4tmWtogJn1buVduB77d3rFoP5ipbu7UDGxGGNzGEJgdZwmZ7NBVSBSEl",
        },
        {
          text: "edit",
          link: "pfbid02WL3cmX1tfiXgvW7UpTxkk1W6cByRpAdn9uo9v68k2kuJkos172aixowJEXSfwnwBl",
        },
        {
          text: "add",
          link: "pfbid0jcXMvZa3kJAS2Lv1CuKqrQCczHXa3TZ5WqsDSREE93xAc3vAUL4Eef9HkGKaHuGol",
        },
        {
          text: "product",
          link: "pfbid0zs1wFyAkceUskqH2BVGBb8bLEJ5oTiZcgTdtZ1UNQc6sMUJqDWSk65hkCabiM9LFl",
        },
        {
          text: "order",
          link: "pfbid0q4FUBEsPpnS9SBEDnakEXF8WW8nQLWxrNiWFEY9aYy9oHUvoTBLEbuWaz2PK4PTml",
        },
        {
          text: "employee",
          link: "pfbid0HsqhkVrSnyrhDsdYNAtC8p2GrXzUpuTW9RQvCSwoZsRPSGDWhXBfpivzKtt8seo7l",
        },
      ],
    };
  },
};
</script>
